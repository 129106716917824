import styled from 'styled-components';
import Image from '../images/Hero.png';
import { PRIMARY_COLOR } from '../constants/colors';

export const Hero = styled.div`
  background-image: url(${Image});
  background-size: auto 380px;
  background-repeat: no-repeat;
  background-position: 90% top;
  height: 380px;
`;

export const Logo = styled.div`
  font-family: Playfair Display;
  font-size: 92px;
  line-height: 1;
  font-weight: 900;
  margin-top: 70px;
`;

export const Entry = styled.div`
  font-family: Muli;
  text-align: center;
  font-size: 24px;
  margin: 50px 0;
  line-height: 1.5;
  color: #777;
`;

export const MainTopicsContainer = styled.div`
  margin-top: 80px;

  a {
    color: ${PRIMARY_COLOR};
    text-align: center;
    border: 4px ${PRIMARY_COLOR} solid;
    padding: 50px 30px 30px;
    border-radius: 250px;
    margin: 0 auto 30px;
    display: inline-block;
    width: 250px;
    height: 250px;
    padding-top: 58px;
    text-decoration: none;
    transition: all .4s linear;

    h3 {
      font-weight: 500;
    }

    svg {
      fill: ${PRIMARY_COLOR};
      width: 70px;
      margin-bottom: 5px;
    }

    &:hover {
      background: #faab06;

      h3 {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }
`;

export const HeroQuote = styled.div`
  text-align: center;
  line-height: 1.5;
  margin-top: 100px;

  .quote {
    font-family: Playfair Display;
    font-style: italic;
    font-size: 36px;
  }

  .author {
    color: #999;
    margin-top: 25px;
  }
`;

import React from 'react';
import { Link } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import Breaker from '../components/Breaker';
import SEO from '../components/Seo';
import { RECIPES_PATH, EVENTS_PATH, COACHING_PATH, WHO_AM_I_PATH } from '../constants/Routes';
import {
  Hero,
  Logo,
  Entry,
  MainTopicsContainer,
  HeroQuote
} from '../styles/home';

import SalverIcon from './icons/Salver.svg';
import WokIcon from './icons/Wok.svg';
import ChefHatIcon from './icons/ChefHat.svg';
import SpeachBubblesIcon from './icons/SpeachBubbles.svg';

const MAIN_TOPICS = [
  {
    icon: <WokIcon />,
    title: 'Receitas',
    path: RECIPES_PATH
  },
  {
    icon: <SalverIcon />,
    title: 'Eventos',
    path: EVENTS_PATH
  },
  {
    icon: <ChefHatIcon />,
    title: 'Quem Sou',
    path: WHO_AM_I_PATH
  },
  {
    icon: <SpeachBubblesIcon />,
    title: 'Coaching',
    path: COACHING_PATH
  }
];

const Home = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`]} />

    <Hero className="homepage-header">
      <Grid>
        <Row>
          <Col md={6} xs={12}>
            <Logo className="homepage-logo">
              Na Caverna <br /> da Marta
            </Logo>
          </Col>
        </Row>
      </Grid>
    </Hero>

    <Breaker />

    <Entry>
      <Grid>
        <Row center="md">
          <Col md={6}>
            Chef de cozinha, especialista em receitas Paleo, formadora, empresária e
            Health &amp; Wellness Coach, Instrutora de Nirvana Fitness e praticante assídua
            de mindfulness, amante de leitura.
          </Col>
        </Row>
      </Grid>
    </Entry>

    <MainTopicsContainer>
      <Grid>
        <Row>
          {MAIN_TOPICS.map((topic, i) => (
            <Col md={3} style={{ textAlign: 'center' }} key={i}>
              <Link  to={topic.path}>
                {topic.icon}
                <h3>{topic.title}</h3>
              </Link>
            </Col>
          ))}
        </Row>
      </Grid>
    </MainTopicsContainer>

    <HeroQuote>
      <div className="quote">"Com a Marta tudo é simples, fácil e saboroso."</div>
      <div className="author">– Tânia Raposo</div>
    </HeroQuote>
  </Layout>
)

export default Home;
